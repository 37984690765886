<script>
  export let
    actions = [],
    execute,
    icon,
    align = 'left',
    asMain = false,
    label = 'More Actions',
    asLight=false;

  let open = false;

  $: style = align === 'left'? `left: 0`: 'right: 0';

  function apply(action) {
    !(action.disabled && action.disabled()) && execute(action);
    open = false;
  }
</script>

{#if actions && actions.length > 0}
  {#if open}
    <div class="bg" on:click={() => (open = !open)} />
  {/if}

  <div class="more">
    <button
      class="aBtn asLarge"
      class:asMain
      class:atDark={!asLight}
      class:atLight={asLight}
      data-icon={icon}
      on:click={() => (open = !open)}
    >
      {label}
      <i class="material-icons">
        {open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
      </i>

      <slot name="top" />
    </button>
    {#if open}
      <div class="Menu" {style} class:asLight>
        {#each actions as action}
          <div
            class="Item"
            on:click={() => apply(action)}
            class:disabled={action.disabled && action.disabled()}
          >
            {#if action.icon}
              <i class="material-icons">{action.icon}</i>
            {/if}
            {action.label}
          </div>
        {/each}

        <slot name="dropdown" />
      </div>
    {/if}
  </div>
{/if}

<style lang="stylus">.more {
  position: relative;
  z-index: 11;
  margin: 0 5px;
  display: inline-flex;
}
.bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}
.Menu {
  min-width: 180px;
  position: absolute;
  top: 40px;
  padding: 10px 0;
  border-radius: 5px;
  background: #263239;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
  max-height: 50vh;
  overflow: auto;
}
.Menu.asLight {
  color: #263239;
  background: #fff;
}
.Item {
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.Item .material-icons {
  margin-right: 5px;
}
.Item:hover {
  background: #313b42;
}
.asLight .Item:hover {
  background: #ececec;
}
.disabled {
  opacity: 0.5;
}
.aBtn {
  display: flex;
  align-items: center;
}
</style>
