import SendRateLoadingRequestActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/SendRateLoadingRequestActionReport.vue';
import SendRateLoadingRequestModal from './SendRateLoadingRequestModal.svelte';
import MarkRatesAsLoadedActionReport from 'vRoot/rfp-hotel/bid-manager/actions/action-report/MarkRatesAsLoadedActionReport.vue';
import MarkRatesAsLoadedModal from './MarkRatesAsLoadedModal.svelte';
import {SvelteDialog} from 'root/v-app/rbServices';
import noop from 'lodash/noop';

export function RequestRateLoading(handleActionResultFn){
  return function (selectedBidsReport){
    const resend = isResend(selectedBidsReport.bids);
    return {
      label: `${resend ? 'Res' : 'S'}end Rates Loaded Request`,
      icon: 'update',
      isAvailable: () => {
        return isFinalAgreement(selectedBidsReport.statuses) && notLoadedOrInvalid(selectedBidsReport.bids);

        function isFinalAgreement(statuses){
          return statuses.length === 1 && statuses[0] === 'FINAL_AGREEMENT'
        }

        function notLoadedOrInvalid(bids){
          return bids.every(b => !b.rateLoadingStatus || ['INVALID', 'PENDING'].includes(b.rateLoadingStatus))
        }
      },
      action: () => {
        SvelteDialog.show(SendRateLoadingRequestModal, {bids: selectedBidsReport.bids, resend})
          .then(result => handleActionResultFn(result, selectedBidsReport.bids, SendRateLoadingRequestActionReport))
          .catch(noop);
      }
    };
  }

  function isResend(bids){
    return bids.every(b => b.rateLoadingStatus === 'PENDING');
  }
}


export function MarkRatesAsLoaded(handleActionResultFn){
  return function (selectedBidsReport){
    return {
      label: 'Mark Rates as Loaded',
      icon: 'published_with_changes',
      isAvailable: () => {
        return isFinalAgreement(selectedBidsReport.statuses) && notLoaded(selectedBidsReport.bids);

        function isFinalAgreement(statuses){
          return statuses.length === 1 && statuses[0] === 'FINAL_AGREEMENT'
        }

        function notLoaded(bids){
          return bids.every(b => b.rateLoadingStatus !== 'LOADED')
        }
      },
      action: () => {
        SvelteDialog.show(MarkRatesAsLoadedModal, {bidsIds: selectedBidsReport.bidsIds, statusLoaded: true})
          .then(result => handleActionResultFn(result, selectedBidsReport.bids, MarkRatesAsLoadedActionReport))
          .catch(noop);
      }
    };
  }
}


export function MarkRatesAsNotLoaded(handleActionResultFn){
  return function (selectedBidsReport){
    return {
      label: 'Mark Rates as Not Loaded',
      icon: 'unpublished',
      isAvailable: () => {
        return isFinalAgreement(selectedBidsReport.statuses) && notLoaded(selectedBidsReport.bids);

        function isFinalAgreement(statuses){
          return statuses.length === 1 && statuses[0] === 'FINAL_AGREEMENT'
        }

        function notLoaded(bids){
          return bids.every(b => b.rateLoadingStatus !== 'LOADED')
        }
      },
      action: () => {
        SvelteDialog.show(MarkRatesAsLoadedModal, {bidsIds: selectedBidsReport.bidsIds, statusLoaded: false})
          .then(result => handleActionResultFn(result, selectedBidsReport.bids, MarkRatesAsLoadedActionReport))
          .catch(noop);
      }
    };
  }
}
